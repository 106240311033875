/*
 * @Author: kaikai.huang
 * @Date: 2023-10-17 14:04:11
 * @LastEditors: kaikai.huang
 * @LastEditTime: 2023-11-07 15:25:06
 * @Description:
 */
import React, { useMemo, useState } from 'react';
import '@/styles/globals.less';
import '@/styles/grid.less';
import { AppProps } from 'next/app';
import Head from 'next/head';
import Navigator from '@/components/Navigator';
import Footer from '@comp/Footer';
import TopNotification from '@comp/TopNotification';
import Sentry from '@/utils/sentry';
import { getSchemaNodes } from '@/utils/schema';
import { getMSiteHost } from '@/utils/resource';
import { getPageInfo } from '@/utils/misc';
import { COUNTRY } from '@const/index';
import '../init/day';

const host = getMSiteHost();

const shareImg = ({
  id: `${host}/assets/image/seabank-share.png`,
  ph: `${host}/assets/image/seabank-share.png`,
  sg: `${host}/assets/image/maribank-share.png`,
} as Record<string, string>)[COUNTRY];

const favicon = ({
  id: '/favicon.png',
  ph: '/favicon.png',
  sg: '/favicon-sg.png',
} as Record<string, string>)[COUNTRY];

function App({ Component, pageProps, router }: AppProps) {
  const { title, desc, path } = getPageInfo(router, pageProps);
  const link = router.asPath === '/' ? host : `${host}${path}`;
  const [runtimeState, setRuntimeState] = useState<AppRuntimeState>({});
  const url = useMemo(() => {
    if (runtimeState.link) {
      return `${host}${runtimeState.link}`;
    } else {
      return link;
    }
  }, [link, runtimeState.link]);

  return (
    <Sentry.ErrorBoundary>
      <Head>
        <title>{runtimeState.title || title}</title>
        <meta name="description" content={runtimeState.description || desc} />
        <meta name="robots" content="index, follow" />
        {/* Cancel the automatic zoom for ios */}
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
        <meta property="og:title" content={runtimeState.title || title} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={url} />
        <meta property="og:image" content={shareImg} />
        <meta
          property="og:description"
          content={runtimeState.description || desc}
        />
        <link rel="canonical" href={url} />
        {(runtimeState.link || path) === '/help-center/hot-questions' ? (
          <>
            <link
              rel="alternate"
              href="https://www.seabank.co.id/pusat-bantuan/pertanyaan-sering-diajukan"
              hrefLang="id-ID"
            />
            <link
              rel="alternate"
              href="https://www.seabank.ph/help-center/hot-questions"
              hrefLang="en-PH"
            />
            <link
              rel="alternate"
              href="https://www.maribank.sg/help-centre/hot-questions"
              hrefLang="en-SG"
            />
          </>
        ) : null}
        <link rel="icon" href={favicon} type="image/png" />
        <meta
          httpEquiv="Cache-Control"
          content="no-cache, no-store, must-revalidate"
        />
        <meta httpEquiv="Pragma" content="no-cache" />
        <meta httpEquiv="Expires" content="0" />
        <base target="_blank" />
        {getSchemaNodes({
          route: router,
          props: pageProps,
          questions: runtimeState.questions,
        })}
      </Head>
      <div className="header-wrap">
        {process.env.NOTIFICATION_DISABLED !== 'true' ? (
          <TopNotification
            {...pageProps.template?.topNotice}
            title={pageProps.title}
            keyword={pageProps.keyword}
          />
        ) : null}
        {!(pageProps.hiddenHeader || runtimeState.hiddenHeader) && (
          <Navigator {...pageProps.template?.header} />
        )}
      </div>
      <div className="content-wrap">
        <Component {...pageProps} setRuntimeState={setRuntimeState} />
        {!(pageProps.hiddenFooter || runtimeState.hiddenFooter) && (
          <Footer {...pageProps.template?.footer} />
        )}
      </div>
    </Sentry.ErrorBoundary>
  );
}

export default App;
